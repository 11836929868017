import {IAppConfig} from "./interfaces";
import {parseISO} from "date-fns";

class AppConfig implements IAppConfig {
    /**
     * Production, development, etc.
     */
    Environment: string = "";
    ApiGateway: string = "";
    DocumentServiceEndpoint: string = "";
    ProductImageServiceEndpoint: string = "";
    PublicProductBaseUrl: string = "";
    SocketIoEndpoint: string = "";
    UploadDocumentsUrl: string = "";
    ProAccountApplicationUrl: string = "";
    /**
     * The tracing ratio is a number between 0 and 1 to determine
     * which proportion of traces are sent to Sentry. In production
     * this should be less than 1.
     */
    TracingRatio: number = 0;
    PasswordAuthenticationDisabledFrom: Date = parseISO("2020-01-01");
    NftAddArtUrl: string = "";
    QrCodeGeneratorUrl: string = "";
    AlgorandNetwork: string = "";
    BuildHash: string = "";
}

export default new AppConfig();
