import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from "@apollo/client";
import * as serviceWorker from './serviceWorker';
import logger from "./logging";
import {IAppConfig} from "./interfaces";
import AppConfig from "./globalConfig";
import {parseISO} from "date-fns";

// Enable console debugging during development
// without worrying about leaving logging lines in place
// for production.
if (process.env.NODE_ENV !== 'production') {
    localStorage.setItem('debug', 'offshoot-admin:*');
}

/**
 * We need to read some config before we can initialise the app.
 */
logger.info('Fetching config from server...');
fetch('/config').then(res => res.json()).then((data: IAppConfig) => {
    AppConfig.Environment = data.Environment;
    AppConfig.ApiGateway = data.ApiGateway;
    AppConfig.DocumentServiceEndpoint = data.DocumentServiceEndpoint.replace(/\/$/, "");
    AppConfig.ProductImageServiceEndpoint = data.ProductImageServiceEndpoint.replace(/\/$/, "");
    AppConfig.PublicProductBaseUrl = data.PublicProductBaseUrl.replace(/\/$/, "");
    AppConfig.SocketIoEndpoint = data.SocketIoEndpoint.replace(/\/$/, "");
    AppConfig.UploadDocumentsUrl = data.UploadDocumentsUrl;
    AppConfig.ProAccountApplicationUrl = data.ProAccountApplicationUrl;
    AppConfig.TracingRatio = data.TracingRatio;
    AppConfig.PasswordAuthenticationDisabledFrom = parseISO(data.PasswordAuthenticationDisabledFrom as string);
    AppConfig.NftAddArtUrl = data.NftAddArtUrl;
    AppConfig.QrCodeGeneratorUrl = data.QrCodeGeneratorUrl;
    AppConfig.AlgorandNetwork = data.AlgorandNetwork;
    AppConfig.BuildHash = data.BuildHash;
    logger.info(`API GW is ${AppConfig.ApiGateway}`);
    bootstrap(AppConfig.ApiGateway).then(() => {});
})

/**
 * Bootstraps the app by dynamically importing components that require
 * configuration information prior to them being imported (e.g. Sentry
 * is initialised in the Shell component, so we can't import App until
 * the environment config is known).
 *
 * @param apiGateway
 */
async function bootstrap(apiGateway: string) {
    const mod = await import('./apolloClient');
    const app = await import('./App');
    const App = app.default;
    const apolloClient = mod.default(apiGateway);
    ReactDOM.render(
        <ApolloProvider client={apolloClient}>
            <App />
        </ApolloProvider>,
        document.getElementById('root')
    );
}

serviceWorker.unregister();
